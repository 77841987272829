<template>
    <div>
        <div class="col-sm-8">
            <h3 style="margin-top:0;">Consultas Técnicas</h3>
        </div>
        <div class="col-sm-4" v-if="permissoesEscrita">
            <div class="pull-right">
                <button class="btn btn-primary"
                        v-on:click="showConsultasEmailsConfig = true">
                    <i class="fa fa-cog"></i> Emails Novas Questões
                </button>
            </div>
        </div>
        <div class="col-sm-12">
            <ul class="nav nav-tabs hidden-print">
                <li role="presentation" :class="{'active' : view === 'ABERTAS'}">
                    <a href="#" @click="openViewAbertas">Em Aberto <span v-show="abertasCount > 0" class="badge badge-danger">{{abertasCount}}</span></a>
                </li>
                <li role="presentation" :class="{'active' : view === 'PROCESSAMENTO'}">
                    <a href="#" @click="openViewProcessamento">Em Processamento <span v-show="procCount > 0" class="badge badge-danger">{{procCount}}</span></a>
                </li>
                <li role="presentation" :class="{'active' : view === 'FECHADAS'}">
                    <a href="#" @click="openViewFechadas">Fechadas</a>
                </li>
                <li role="presentation" :class="{'active' : view === 'STATS'}">
                    <a href="#" @click="openViewStats">Estatísticas</a>
                </li>
            </ul>
        </div>
        <div class="col-sm-12" v-if="view === 'ABERTAS'">
            <div style="margin-top:10px;"></div>
            <div v-if="abertasLoading">
                <i class="fa fa-2x fa-spinner fa-spin"></i>
            </div>
            <div class="form-inline" v-if="!abertasLoading">
                <div class="form-group" style="width: calc(100% - 56px);">
                    <input v-model="filter" type="text"
                           @keyup.enter="loadConsultasAbertas"
                           class="form-control input-lg"
                           style="width:100%;"
                           placeholder="texto a pesquisar"/>
                </div>
                <v-button :loading="abertasLoading" @click.native="loadConsultasAbertas" class="btn btn-default btn-lg" style="margin-left: 5px;"><i class="fa fa-search"></i></v-button>
            </div>
            <div style="margin-top:10px;"></div>
            <div v-if="!abertasLoading && abertas.length === 0" class="text-center">
                <h3>Sem resultados</h3>
            </div>
            <div v-if="!abertasLoading">
                <imp-questao-list-item v-for="item in abertas"
                                        :key="item.id"
                                        @open-questao="openQuestao(item.id)"
                                        :questao="item"
                >
                </imp-questao-list-item>
            </div>
        </div>
        <div class="col-sm-12" v-if="view === 'PROCESSAMENTO'">
            <div style="margin-top:10px;"></div>
            <div v-if="procLoading">
                <i class="fa fa-2x fa-spinner fa-spin"></i>
            </div>
            <div class="form-inline" v-if="!procLoading">
                <div class="form-group" style="width: calc(100% - 56px);">
                    <input v-model="filter" type="text"
                           @keyup.enter="loadConsultasProcessamento"
                           class="form-control input-lg"
                           style="width:100%;"
                           placeholder="texto a pesquisar"/>
                </div>
                <v-button :loading="procLoading" @click.native="loadConsultasProcessamento" class="btn btn-default btn-lg" style="margin-left: 5px;"><i class="fa fa-search"></i></v-button>
            </div>
            <div style="margin-top:10px;"></div>
            <div v-if="!procLoading && processamento.length === 0" class="text-center">
                <h3>Sem resultados</h3>
            </div>
            <div v-if="!procLoading">
                <imp-questao-list-item v-for="item in processamento"
                                        :key="item.id"
                                        @open-questao="openQuestao(item.id)"
                                        :questao="item"
                >
                </imp-questao-list-item>
            </div>
        </div>
        <div class="col-sm-12" v-if="view === 'FECHADAS'">
            <div style="margin-top:10px;"></div>
            <div class="form-inline">
                <div class="form-group" style="width: calc(100% - 56px);">
                    <input v-model="filter" type="text"
                           @keyup.enter="fechadasSearch"
                           class="form-control input-lg"
                           style="width:100%;"
                           placeholder="texto a pesquisar"/>
                </div>
                <v-button :loading="fechadasLoading" @click.native="fechadasSearch" class="btn btn-default btn-lg" style="margin-left: 5px;"><i class="fa fa-search"></i></v-button>
            </div>
            <div style="margin-top:10px;"></div>
            <imp-questao-list-item v-for="item in fechadas"
                                   :key="item.id"
                                   @open-questao="openQuestao(item.id)"
                                   :questao="item"
            >
            </imp-questao-list-item>
            <infinite-loading spinner="circles" :identifier="fechadasInfiniteId" @infinite="fechadasInfiniteHandler">
                <div slot="no-more"></div>
                <div slot="no-results"><h3>Sem resultados</h3></div>
            </infinite-loading>
        </div>
        <div class="col-sm-12" v-if="view === 'STATS'">
            <imp-consultas-stats ></imp-consultas-stats>
        </div>
        <consultas-emails-config-modal :show.sync="showConsultasEmailsConfig"></consultas-emails-config-modal>
    </div>

</template>
<style scoped>
    .badge-danger {
        background-color: #a94442;
    }
</style>
<script>
    // @ is an alias to /src
    import axios from 'axios'
    import ImpQuestaoListItem from "./ImpQuestaoListItem";
    import ImpConsultasStats from "./ImpConsultasStats";
    import ConsultasEmailsConfigModal from "./modal/ConsultasEmailsConfigModal";
    import InfiniteLoading from "vue-infinite-loading";
    import VButton from "../components/VButton";
    // import { showError } from '@/utils.js'

    export default {
        components: {
            ConsultasEmailsConfigModal,
            ImpQuestaoListItem,
            ImpConsultasStats,
            InfiniteLoading,
            VButton,
        },
        data() {
            return {
                view: "ABERTAS",
                abertasCount: 0,
                abertasLoading: false,
                abertas: [],

                procCount: 0,
                procLoading: false,
                processamento: [],

                fechadasPage: 0,
                fechadasPageSize: 100,
                fechadas: [],

                fechadasInfiniteId: +new Date(),
                fechadasLoading: false,

                filter: '',

                showConsultasEmailsConfig: false,

                permissoesEscrita: false,
            }
        },
        mounted() {
            // eslint-disable-next-line
            this.permissoesEscrita = permEsc;
            this.loadConsultasAbertas();
            this.loadConsultasProcessamento();
        },
        computed: {
        },
        methods: {
            openViewAbertas() {
                this.view = 'ABERTAS';
                this.loadConsultasAbertas();
            },
            openViewProcessamento() {
                this.view = 'PROCESSAMENTO';
                this.loadConsultasProcessamento();
            },
            openViewFechadas() {
                this.fechadasPage = 0;
                this.fechadas = [];
                this.view = 'FECHADAS';
            },
            openViewStats() {
                this.view = 'STATS';
            },
            loadConsultasAbertas() {
                const self = this;
                this.abertasLoading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.getConsultasAbertas(this.filter).url;

                // GET request
                axios.get(url).then(function (response) {
                    self.abertasLoading = false;
                    // set data
                    // eslint-disable-next-line
                    self.abertas = response.data;
                    self.abertasCount = response.data.length;
                }).catch(function (error) {
                    self.abertasLoading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError("Erro", error);
                });

            },
            loadConsultasProcessamento() {
                const self = this;
                this.procLoading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.getConsultasProcessamento(this.filter).url;

                // GET request
                axios.get(url).then(function (response) {
                    self.procLoading = false;
                    // set data
                    // eslint-disable-next-line
                    self.processamento = response.data;
                    self.procCount = response.data.length;
                }).catch(function (error) {
                    self.procLoading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError("Erro", error);
                });

            },
            fechadasSearch() {
                this.fechadasPage = 0;
                this.fechadas = [];
                this.fechadasInfiniteId += 1;
            },
            fechadasInfiniteHandler($state) {
                const self = this;
                self.fechadasLoading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.getConsultasFechadas(this.fechadasPage, this.fechadasPageSize, this.filter).url;
                axios.get(url).then(response => {
                    self.fechadasLoading = false;
                    if (response.data.length) {
                        this.fechadasPage += 1;
                        this.fechadas.push(...response.data);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                }).catch(function (error) {
                    self.fechadasLoading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError("Erro", error);
                });
            },
            openQuestao(id) {
                this.$router.push({ name: 'ImpDetalheConsulta', params: { id: id } })
            }
        }
    }
</script>
