<template>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 style="margin-top:0;">Questões fechadas por mês</h3>
        </div>
        <div class="panel-body">
            <div class="container-fluid">
                <div class="form-horizontal">
                    <div class="form-group">
                        <label class="control-label col-md-1 col-xs-6">Mês</label>
                        <div class="col-md-2 col-xs-6">
                            <month-picker :value.sync="month" style="width: 160px;"></month-picker>
                        </div>
                        <v-button :loading="loading" @click.native="loadHistorico" class="btn btn-primary">Consultar</v-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <div class="table-responsive">
                <table class="table table-bordered table-condensed table-striped table-hover" ref="tabela">
                    <thead>
                    <tr>
                        <th>Concessionário</th>
                        <th>Respostas Fechadas</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, $index) in lista" :key="$index">
                        <td>{{item.conc}}</td>
                        <td>{{item.total}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<style scoped>

</style>
<script>
    // @ is an alias to /src
    import axios from "axios";
    import MonthPicker from "../components/MonthPicker";
    import VButton from "../components/VButton";

    export default {
        components: {
            VButton,
            MonthPicker,
            // VButton
        },
        props: ["id"],
        data() {
            return {
                // eslint-disable-next-line
                month: moment().format("YYYY-MM"),

                loading: false,

                lista: [],
            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            loadHistorico() {
                this.loading = true;
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.consultasFechadasStats(this.month).url;

                // GET request
                axios.get(url).then(function (response) {
                    // eslint-disable-next-line
                    const dT = $(self.$refs.tabela).DataTable();
                    if (dT !== null) {
                        dT.destroy();
                    }
                    self.loading = false;
                    // set data
                    // eslint-disable-next-line
                    self.lista = response.data;
                    self.$nextTick(function () {
                        self.setupDataTable();
                    });
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError("Erro ao obter detalhe de questão", error);
                });
            },
            setupDataTable: function () {
                // $.fn.dataTable.moment('DD-MM-YYYY');
                const dtOptions = {
                    "info": false,
                    "paging": false,
                    "searching": true,
                    "ordering": true,
                    //"ordering": true,
                    //"order": [[0, "desc"]],
                    buttons: [
                        'excel',
                        {
                            extend: 'pdf',
                            orientation: 'landscape',
                            pageSize: 'A4'
                        },
                    ],
                    "dom": 'Bfrtp',
                    // eslint-disable-next-line
                    "language": datatableLanguage,
                };

                // eslint-disable-next-line
                $(this.$refs.tabela).DataTable(dtOptions);
            },
        }
    }
</script>
