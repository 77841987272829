<template>
    <select class="selectpicker" ref="select">
        <option v-for="(item, $index) in items" :value="item.id" :key="$index">{{item.text}}</option>
    </select>
</template>
<script>
    // $.fn.selectpicker.Constructor.BootstrapVersion

    // import 'bootstrap-select/dist/css/bootstrap-select.min.css'
    // import 'bootstrap-select';

    export default {
        name: "BSelect",
        props: {
            value: {
                default() {
                    return ""
                }
            },
            items: {
                type: Array,
                default() {
                    return []
                }
            },
            disabled: {
                type: Boolean,
                default() {
                    return false
                }
            },
        },
        data: function () {
            return {};
        },
        watch: {
            items: function () {
                // eslint-disable-next-line
                let select = $(this.$refs.select);
                const self = this;
                this.$nextTick(function () {
                    select.selectpicker('refresh');
                    select.selectpicker('val', self.value);
                });
            },
            value: function () {
                // eslint-disable-next-line
                let select = $(this.$refs.select);
                if (this.value !== select.selectpicker("val")) {
                    select.selectpicker("val", this.value);
                }
            },
            disabled: function () {
                // eslint-disable-next-line
                let select = $(this.$refs.select);
                select.prop('disabled', this.disabled);
                select.selectpicker("refresh");
            }
        },
        mounted() {
            // eslint-disable-next-line
            let select = $(this.$refs.select);
            select.selectpicker();
            select.selectpicker("val", this.value);
            select.prop('disabled', this.disabled);
            select.selectpicker("refresh");
            let self = this;
            select.on("changed.bs.select", function () {
                self.$emit('update:value', select.selectpicker("val"));
            });
        }
    }
</script>
