import Vue from 'vue'
import VueRouter from 'vue-router'
import ImpConsultasTecnicasHome from "./ImpConsultasTecnicasHome";
import ImpDetalheConsulta from "./ImpDetalheConsulta";

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'ImpConsultasTecnicasHome',
            component: ImpConsultasTecnicasHome
        },
        {
            path: '/:id',
            name: 'ImpDetalheConsulta',
            component: ImpDetalheConsulta,
            props: true
        },
    ]
})
