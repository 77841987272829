<template>
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Emails Novas Questões</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="control-label">
                            Selecione os destinatários de emails:</label>
                        <b-select :value.sync="selectedUsers" :items="usersImportador" multiple data-live-search="true" data-width="100%"/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
                    <v-button type="button" class="btn btn-primary"
                              :loading="loading"
                              v-on:click.prevent="saveConfigs">
                        Atualizar</v-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    import VButton from "../../components/VButton";
    import BSelect from "../../components/BSelect";

    export default {
        components: {
            VButton,
            BSelect
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                loading: false,

                usersImportador: [],

                selectedUsers: [],
            }
        },
        mounted() {
            let self = this;
            // eslint-disable-next-line
            $(this.$refs.modal).on('hidden.bs.modal', function (e) {
                self.close()
            });

        },
        watch: {
            show() {
                if (this.show) {
                    // eslint-disable-next-line
                    $(this.$refs.modal).modal('show');
                    this.loadUsers();
                    this.loadConfigs();
                }
            },
        },
        computed: {
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            loadUsers: function () {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpExistenciasController.loadUsersImportador().url;
                axios.get(url).then(function (value) {
                    self.usersImportador = value.data;
                }, function (error) {
                    // eslint-disable-next-line
                    handleAjaxError("Erro", error);
                    // showError('Erro', 'Ocorreu um erro no carregamento de configurações de destinatários de email.');
                });
            },

            loadConfigs: function () {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.loadEmailsNovasConsultasConfigs().url;
                axios.get(url).then(function (value) {
                    if (value.data && value.data.users) {
                        self.selectedUsers = value.data.users.split(',');
                    }
                }, function (error) {
                    // eslint-disable-next-line
                    handleAjaxError("Erro", error);
                });
            },

            saveConfigs: function () {
                const self = this;
                self.loading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.updateEmailsNovasConsultasConfigs().url;

                const emailConfig = {
                    users: this.selectedUsers.join(',')
                };

                axios.post(url, emailConfig).then(function () {
                    self.loading = false;
                    // eslint-disable-next-line
                    showSuccess('Sucesso', 'Endereços de email atualizados.');
                    // eslint-disable-next-line
                    $(self.$refs.modal).modal('hide');
                    self.selectedUsers = [];
                }, function (error) {
                    self.loading = false;
                    // eslint-disable-next-line
                    handleAjaxError('Ocorreu um erro na atualização dos endereços de email.', error);
                });


            },
        }
    }
</script>

<style scoped>

</style>
