<template>
    <div class="input-group date" ref="inputgroup">
        <input type="text" class="form-control" v-model="value">
        <span class="input-group-addon"><i class="glyphicon glyphicon-calendar"></i></span>
    </div>
</template>

<script>
    // import $ from 'jquery';

    export default {
        props: {
            value: {
                default() {
                    return '';
                }
            }
        },
        data: function () {
            return {};
        },
        watch: {
            value: function () {
                this.datepicker.datepicker("update", this.value);
            },
        },
        mounted: function () {
            const self = this;
            // eslint-disable-next-line
            this.datepicker = $(this.$refs.inputgroup).datepicker({
                format: 'yyyy-mm',
                minViewMode: 1,
                language: "pt",
                autoclose: true
            })
                .on("changeDate", function() {
                    // eslint-disable-next-line
                    self.$emit('update:value', moment(self.datepicker.datepicker("getDate")).format('YYYY-MM'));
                });
        }
    }
</script>

<style scoped>

</style>
