<template>
    <div class="panel panel-default">
        <ul class="list-group">
            <button class="list-group-item" @click="$emit('open-questao')">
                <div class="col-md-8 col-sm-12">
                    <div class="texto-chassis">
                        {{questao.chassis}}
                    </div>
                    <div class="texto-assunto">
                        {{questao.assunto}}
                    </div>
                    <div class="texto-descricao">
                        {{questao.descricao}}
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="questao-prop">Actualizada: </div>
                    <dynamic-from-now :val="questao.dataActualizacao ? questao.dataActualizacao : questao.dataCriacao"></dynamic-from-now>
                    <br/>
                    <span class="questao-prop">Criada: </span>
                    <dynamic-from-now :val="questao.dataCriacao"></dynamic-from-now>
                    <br/>
                    <span class="questao-prop">Criada por: </span>
                    <span v-tooltip="questao.criadaEmail">{{questao.criadaNome}}</span>
                </div>
            </button>
        </ul>
    </div>
</template>
<style scoped>
    .texto-chassis {
        font-weight: bold;
        /*font-size: large;*/
    }
    .texto-assunto {
        font-weight: bold;
        font-size: large;
    }

    .texto-descricao {
        color: gray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .questao-prop {
        display:inline-block;
        text-align: right;
        width: 100px;
        font-weight: bold;
        padding-right: 10px;
    }
</style>
<script>
    // @ is an alias to /src
    // import axios from 'axios'

    import DynamicFromNow from "../components/DynamicFromNow";
    export default {
        components: {
            DynamicFromNow
        },
        props: ["questao"],
        data() {
            return {
            }
        },
        validations: {
        },
        mounted() {
        },
        methods: {
            fromNow(data) {
                // eslint-disable-next-line
                return moment(data, "DD-MM-YYYY HH:mm:ss").fromNow();
            },
        }
    }
</script>
