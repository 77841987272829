<template>
    <div class="col-sm-12">
        <div v-show="loading">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
        <div v-show="!loading">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-sm-6">
                            {{detalhe.criadaNome}} - {{detalhe.concessionario}}
                        </div>
                        <div class="col-sm-6 text-right">
                            <dynamic-from-now :val="detalhe.dataCriacao"></dynamic-from-now>
                        </div>
                    </div>
                    <h4 style="margin-top:5px;margin-bottom: 0;">{{detalhe.chassis}}</h4>
                    <h3 style="margin-top:0;">{{detalhe.assunto}}</h3>
                </div>
                <div class="panel-body">
                    {{detalhe.descricao}}
                </div>
                <ul class="list-group" v-if="detalhe.ficheiros.length > 0">
                    <li class="list-group-item" v-for="ficheiro in detalhe.ficheiros" :key="ficheiro.id">
                        <a v-if="ficheiro.isImagem" href="#" @click.stop.prevent="showImagemModal(ficheiro)">
                            <i class="fa fa-file-image-o"></i> {{ficheiro.filename}}
                        </a>
                        <a v-else :href="ficheiroDownloadUrl(ficheiro)">
                            <i class="fa fa-file-o"></i> {{ficheiro.filename}}
                        </a>
                    </li>
                </ul>
            </div>
            <div v-for="item in detalhe.respostas"
                 :key="item.id"
                 class="panel"
                 :class="[item.importador ? 'panel-danger' : 'panel-default']">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-sm-8" v-if="item.importador">
                            {{item.nome}} - Importador
                        </div>
                        <div class="col-sm-8" v-else>
                            {{item.nome}}
                        </div>
                        <div class="col-sm-4 text-right">
                            <dynamic-from-now :val="item.data"></dynamic-from-now>
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                    {{item.resposta}}
                </div>
                <ul class="list-group" v-if="item.ficheiros.length > 0">
                    <li class="list-group-item" v-for="ficheiro in item.ficheiros" :key="ficheiro.id">
                        <a v-if="ficheiro.isImagem" href="#" @click.stop.prevent="showImagemModal(ficheiro)">
                            <i class="fa fa-file-image-o"></i> {{ficheiro.filename}}
                        </a>
                        <a v-else :href="ficheiroDownloadUrl(ficheiro)">
                            <i class="fa fa-file-o"></i> {{ficheiro.filename}}
                        </a>
                    </li>
                </ul>
            </div>
            <div v-if="detalhe.estado === 'FECHADA'" class="panel panel-success">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-sm-8">
                            Fechada por {{detalhe.fechoNome}}
                        </div>
                        <div class="col-sm-4 text-right">
                            <dynamic-from-now :val="detalhe.dataActualizacao"></dynamic-from-now>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-default" v-show="modoResponder">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <form novalidate class="form-horizontal">
                                <div class="form-group" :class="{ 'has-error': $v.resposta.$error }">
                                    <label class="control-label col-sm-3">Resposta</label>
                                    <div class="col-sm-9">
                                        <textarea-autosize
                                                ref="responder"
                                                :min-height="150"
                                                :max-height="250"
                                                class="form-control"
                                                v-model.trim="resposta"
                                        ></textarea-autosize>
                                        <span class="help-block"
                                              v-show="!$v.resposta.required && $v.resposta.$error">Campo obrigatório</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-sm-3">Ficheiros</label>
                                    <div class="col-sm-9">
                                        <div class="dropzone" ref="dropzonearea">
                                            <div class="dz-message">
                                                <h3>Arrastar ficheiros para carregar</h3><br>
                                                <span class="note">(Ou clicar com o rato para selecionar ficheiros)</span>
                                            </div>
                                            <select id="filesData" name="files" multiple="multiple" hidden>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <div class="pull-right" v-show="!modoResponder">
                <router-link class="btn btn-link" :to="{name: 'ImpConsultasTecnicasHome'}">Voltar</router-link>
                <button v-if="detalhe.estado === 'ABERTA' || detalhe.estado === 'PROCESSAMENTO'"
                        class="btn btn-primary"
                        style="margin-left: 10px;"
                        @click="startModoResponder"
                >
                    <i class="fa fa-reply"></i> Responder
                </button>
                <v-button v-if="detalhe.estado === 'ABERTA' || detalhe.estado === 'PROCESSAMENTO'"
                          style="margin-left: 10px;"
                          class="btn btn-success"
                          @click.native="fecharQuestao"
                          :loading="fecharLoading"
                >
                    <i class="fa fa-check"></i> Fechar
                </v-button>
                <v-button v-if="detalhe.estado === 'FECHADA'"
                          style="margin-left: 10px;"
                          class="btn btn-success"
                          @click.native="reabrirQuestao"
                          :loading="reabrirLoading"
                >
                    <i class="fa fa-reply"></i> Reabrir
                </v-button>
            </div>
            <div class="pull-right" v-show="modoResponder">
                <button class="btn btn-link" @click="endModoResponder">Cancelar</button>
                <v-button class="btn btn-primary"
                          style="margin-left: 10px;"
                          :loading="responderLoading"
                          @click.native="responderQuestao"
                >
                    <i class="fa fa-reply"></i> Responder
                </v-button>
            </div>
        </div>
    </div>
</template>
<style scoped>

</style>
<script>
    // @ is an alias to /src
    // import axios from 'axios'
    import {required} from 'vuelidate/lib/validators'
    import Vue from 'vue'
    import TextareaAutosize from 'vue-textarea-autosize'
    import Dropzone from 'dropzone';
    import axios from "axios";
    import VButton from "../components/VButton";
    import DynamicFromNow from "../components/DynamicFromNow";

    // Dropzone.autoDiscover = false;

    Vue.use(TextareaAutosize);

    export default {
        components: {
            DynamicFromNow,
            VButton
        },
        props: ["id"],
        data() {
            return {
                detalhe: {
                    assunto: null,
                    descricao: null,
                    dataCriacao: null,
                    respostas: [],
                    ficheiros: [],
                },
                resposta: null,

                modoResponder: false,
                responderLoading: false,
                fecharLoading: false,
                reabrirLoading: false,
                loading: false,
            }
        },
        validations: {
            resposta: {
                required
            }
        },
        mounted() {
            this.setupDropzone();
            this.loadDetalhe();
        },
        computed: {
        },
        methods: {
            /*fromNow(data) {
                // eslint-disable-next-line
                return moment(data, "DD-MM-YYYY HH:mm:ss").fromNow();
            },*/
            ficheiroDownloadUrl: function(ficheiro) {
                // eslint-disable-next-line
                return jsRoutes.controllers.ImpConsultasTecnicasController.ficheiro(ficheiro.id, false).url;
            },
            showImagemModal: function(ficheiro) {
                // eslint-disable-next-line
                const embeddImgUrl = jsRoutes.controllers.ImpConsultasTecnicasController.ficheiro(ficheiro.id, true).url;
                // eslint-disable-next-line
                const imgUrl = jsRoutes.controllers.ImpConsultasTecnicasController.ficheiro(ficheiro.id, false).url;
                // eslint-disable-next-line
                bootbox.alert({
                    message: "<a href='" + imgUrl + "'><img src='" + embeddImgUrl + "' class='img-responsive' style='margin-left: auto;margin-right: auto;'></a>",
                    size: "large",
                });
            },
            loadDetalhe() {
                this.loading = true;
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.getDetalheConsulta(this.id).url;

                // GET request
                axios.get(url).then(function (response) {
                    self.loading = false;
                    // set data
                    // eslint-disable-next-line
                    self.detalhe = response.data;
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError("Erro ao obter detalhe de questão", error);
                });
            },
            setupDropzone: function () {
                const self = this;
                this.dropzone = new Dropzone(this.$refs.dropzonearea, {
                    url: "url", //dropzone.js:434 Uncaught Error: No URL provided
                    paramName: "file", // The name that will be used to transfer the file
                    maxFilesize: 50, // MB
                    autoProcessQueue: false,
                    uploadMultiple: true,
                    addRemoveLinks: true,
                    // acceptedFiles: " .doc, .docx,  .pdf, .jpg, .jpeg, .png, .bmp, .tif, .tiff",
                    error: function (file, errorMessage) {//if the error was due to the XMLHttpRequest the xhr object as third
                        // eslint-disable-next-line
                        showError("Erro", errorMessage);
                        // self.uploadSuccess = false;
                        this.removeAllFiles(true);
                        self.dropzone.processQueue();
                    },

                    success: function (file/*, response*/) {
                        // console.log("#### success");
                        // self.uploadSuccess = true;
                        // self.files.push(response);
                        self.dropzone.processQueue();
                        this.removeFile(file);
                    },

                    queuecomplete: function () {
                        console.log("#### queuecomplete");
                        // if (self.uploadSuccess) {
                        //     // showSuccess("Sucesso", "Ficheiro carregado");
                        //     if (self.files.length > 0) {
                        //         self.filesValid = true;
                        //     }
                        // }
                    },
                });
                // this.dropzoneSetup = true;
            },
            fecharQuestao() {
                this.fecharLoading = true;
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.fecharConsulta().url;

                // GET request
                axios.post(url, {id: this.id}).then(function () {
                    self.fecharLoading = false;
                    // eslint-disable-next-line
                    showSuccess("Questão fechada", "");
                    self.$router.push({name: 'ImpConsultasTecnicasHome'});
                }).catch(function (error) {
                    self.fecharLoading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError(error);
                });
            },
            reabrirQuestao() {
                this.reabrirLoading = true;
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.reabrirConsulta().url;

                // GET request
                axios.post(url, {id: this.id}).then(function (response) {
                    self.reabrirLoading = false;
                    // eslint-disable-next-line
                    showSuccess("Questão reaberta", "");
                    self.detalhe = response.data;
                }).catch(function (error) {
                    self.reabrirLoading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError(error);
                });
            },
            startModoResponder() {
                this.modoResponder = true;
                this.$nextTick(() => {
                    window.scrollTo({
                        left: 0,
                        top: document.body.scrollHeight,
                        behavior: "smooth"
                    });
                    this.$refs.responder.$el.focus();
                });
            },
            endModoResponder() {
                this.modoResponder = false
                this.resposta = null;
                this.$v.$reset();
                this.dropzone.removeAllFiles(true);
            },
            responderQuestao() {
                const self = this;
                this.$v.$touch()
                if (this.$v.$invalid) {
                    // console.error('data invalid');
                    return;
                }

                this.responderLoading = true;
                let formData = new FormData();
                formData.append("id", this.id);
                formData.append("resposta", this.resposta);

                const files = this.dropzone.files;
                files.forEach(function (value, index) {
                    formData.append("ficheiro_" + index, value);
                });
                // const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ImpConsultasTecnicasController.responderConsulta().url;

                axios.post(url, formData).then(function () {
                    self.responderLoading = false;
                    // eslint-disable-next-line
                    showSuccess("Resposta enviada", "")
                    // self.$router.push({name: 'ConcConsultasTecnicasHome'});
                    self.endModoResponder();
                    self.loadDetalhe();
                }).catch(function (error) {
                    self.responderLoading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError(error);
                });
            }
        }
    }
</script>
