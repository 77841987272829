import Vue from 'vue'
import VueRouter from 'vue-router'
import ConcConsultasTecnicasHome from "./ConcConsultasTecnicasHome";
import ConcCriarConsultaTecnica from "./ConcCriarConsultaTecnica";
import ConcDetalheConsulta from "./ConcDetalheConsulta";

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'ConcConsultasTecnicasHome',
            component: ConcConsultasTecnicasHome
        },
        {
            path: '/criar-consulta',
            name: 'CriarConsultaTecnica',
            component: ConcCriarConsultaTecnica
        },
        {
            path: '/:id',
            name: 'ConcDetalheConsulta',
            component: ConcDetalheConsulta,
            props: true
        },
    ]
})
