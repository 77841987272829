<template>
    <span v-tooltip="val">{{fromNow}}</span>
</template>

<script>
    export default {
        props: {
            val: {type: String, default: null},
        },
        data() {
            return {
                intervalId: null,
                fromNow: ""
            }
        },
        mounted() {
            const self = this;
            this.refreshNow();
            this.intervalId = setInterval(() => {
                self.refreshNow();
            }, 10000); // 10 segundos
        },
        beforeDestroy() {
            clearInterval(this.intervalId);
        },
        watch: {
            val() {
                this.refreshNow()
            }
        },
        methods: {
            refreshNow() {
                if (this.val) {
                    // eslint-disable-next-line
                    this.fromNow = moment(this.val, "DD-MM-YYYY HH:mm:ss").fromNow();
                } else {
                    return "";
                }
            }
        }
    }
</script>

<style scoped>

</style>
