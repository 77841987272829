<template>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 style="margin-top:0;">Nova Questão</h3>
        </div>
        <div class="panel-body">
            <div class="row">
                <div class="col-sm-12">
                    <form novalidate class="form-horizontal">
                        <div class="form-group" :class="{ 'has-error': $v.createData.chassis.$error }">
                            <label class="control-label col-sm-3">Chassis *</label>
                            <div class="col-sm-9">
                                <input class="form-control" type="text" v-model="createData.chassis"/>
                                <span class="help-block"
                                      v-show="!$v.createData.chassis.required && $v.createData.chassis.$error">Campo obrigatório</span>
                                <span class="help-block"
                                      v-show="!$v.createData.chassis.minLength && $v.createData.chassis.$error">O chassis tem de ter 17 digitos</span>
                                <span class="help-block"
                                      v-show="!$v.createData.chassis.maxLength && $v.createData.chassis.$error">O chassis tem de ter menos de 45 digitos</span>
                            </div>
                        </div>
                        <div class="form-group" :class="{ 'has-error': $v.createData.assunto.$error }">
                            <label class="control-label col-sm-3">Assunto *</label>
                            <div class="col-sm-9">
                                <input class="form-control" type="text" v-model="createData.assunto"/>
                                <span class="help-block"
                                      v-show="!$v.createData.assunto.required && $v.createData.assunto.$error">Campo obrigatório</span>
                            </div>
                        </div>
                        <div class="form-group" :class="{ 'has-error': $v.createData.descricao.$error }">
                            <label class="control-label col-sm-3">Descrição *</label>
                            <div class="col-sm-9">
                                <textarea-autosize
                                        :min-height="150"
                                        :max-height="250"
                                        class="form-control"
                                        v-model.trim="createData.descricao"
                                ></textarea-autosize>
                                <span class="help-block"
                                      v-show="!$v.createData.descricao.required && $v.createData.descricao.$error">Campo obrigatório</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-sm-3">Ficheiros</label>
                            <div class="col-sm-9">
                                <div class="dropzone" ref="dropzonearea">
                                    <div class="dz-message">
                                        <h3>Arrastar ficheiros para carregar</h3><br>
                                        <span class="note">(Ou clicar com o rato para selecionar ficheiros)</span>
                                    </div>
                                    <select id="filesData" name="files" multiple="multiple" hidden>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="pull-right">
                        <router-link class="btn btn-link" :to="{name: 'ConcConsultasTecnicasHome'}">Cancelar</router-link>
                        <v-button class="btn btn-primary" :loading="loading" @click.native="criarQuestao">Criar Questão</v-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import {required, minLength, maxLength} from 'vuelidate/lib/validators'
    import Vue from 'vue'
    import TextareaAutosize from 'vue-textarea-autosize'
    import Dropzone from 'dropzone';
    import VButton from "../components/VButton";

    // Dropzone.autoDiscover = false;

    Vue.use(TextareaAutosize);

    export default {
        components: {VButton},
        data() {
            return {
                createData: {
                    assunto: null,
                    chassis: null,
                    descricao: null,
                },
                loading: false,
            }
        },
        validations: {
            createData: {
                assunto: {
                    required
                },
                chassis: {
                    required,
                    minLength: minLength(17),
                    maxLength: maxLength(45),
                },
                descricao: {
                    required
                },
            }
        },
        mounted() {
            this.setupDropzone();
        },
        methods: {
            setupDropzone: function () {
                const self = this;
                this.dropzone = new Dropzone(this.$refs.dropzonearea, {
                    url: "url", //dropzone.js:434 Uncaught Error: No URL provided
                    paramName: "file", // The name that will be used to transfer the file
                    maxFilesize: 50, // MB
                    autoProcessQueue: false,
                    uploadMultiple: true,
                    addRemoveLinks: true,
                    // acceptedFiles: " .doc, .docx,  .pdf, .jpg, .jpeg, .png, .bmp, .tif, .tiff",
                    error: function (file, errorMessage) {//if the error was due to the XMLHttpRequest the xhr object as third
                        // eslint-disable-next-line
                        showError("Erro", errorMessage);
                        // self.uploadSuccess = false;
                        this.removeAllFiles(true);
                        self.dropzone.processQueue();
                    },

                    success: function (file/*, response*/) {
                        // console.log("#### success");
                        // self.uploadSuccess = true;
                        // self.files.push(response);
                        self.dropzone.processQueue();
                        this.removeFile(file);
                    },

                    queuecomplete: function () {
                        console.log("#### queuecomplete");
                        // if (self.uploadSuccess) {
                        //     // showSuccess("Sucesso", "Ficheiro carregado");
                        //     if (self.files.length > 0) {
                        //         self.filesValid = true;
                        //     }
                        // }
                    },
                });
                // this.dropzoneSetup = true;
            },

            criarQuestao() {
                const self = this;
                this.$v.$touch()
                if (this.$v.$invalid) {
                    // console.error('data invalid');
                    return;
                }

                this.loading = true;
                let formData = new FormData();
                formData.append("assunto", this.createData.assunto);
                formData.append("chassis", this.createData.chassis);
                formData.append("descricao", this.createData.descricao);

                const files = this.dropzone.files;
                files.forEach(function (value, index) {
                    formData.append("ficheiro_" + index, value);
                });
                // const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ConcConsultasTecnicasController.doCriarConsulta().url;

                // GET request
                axios.post(url, formData).then(function () {
                    self.loading = false;
                    // eslint-disable-next-line
                    showSuccess("Questão criada", "");
                    self.$router.push({name: 'ConcConsultasTecnicasHome'});
                }).catch(function (error) {
                    self.loading = false;
                    // handle error
                    // eslint-disable-next-line
                    handleAjaxError(error);
                });

            }
        }
    }
</script>
